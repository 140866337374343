.fontsize-14{
    font-size: 14px;
}
.fontsize-13{
    font-size: 13px;
}
.minHeight {
    min-height: 100%;
}
.dataTables_info, .page-link {
    font-size:  10px;
}
.dataTables_length {
    padding-left: 1%;
    font-size: 12px;
}
.rightBorder {
    border-right: 4px solid #ccc;
}
.leftMargin {
    margin-left: 1px;;
}
.egMLbj {
    padding: 3px;;
}