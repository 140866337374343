.rowtoadd{
    background-color: rebeccapurple;
    padding-bottom: 1%;
    color: #fff;
    margin-top: 1%;
}
.margin-top-25 {
    margin-top: 25;
}
.fontSize9 {
    font-size: 9px;
    border: 2px solid #ccc;
    padding: 2px;
}
.width40 {
    width: 40%;
}
.width10 {
    width: 10%;
}
.borderTable, td, th {
    border: 1px solid #ccc;
  }
  
  .borderTable {
    width: 100%;
    border-collapse: collapse;
  }